import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsDispatchOutLogDetail',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      from: '',
      allocateOutId: '',
      inApplicant: '',
      inSpaceCode: '',
      inProjectName: '',
      productList: [],
      outKeeper: '',
      outSpaceProjectId: '',
      outTime: '',
      remark: '',
      productPropertyMap,
      totalPrice: '',
      outSpaceListShow: false,
      outSpaceList: []
    };
  },
  created() {
    this.allocateOutId = this.$route.query.allocateOutId;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getAllocateOutDetail(this.allocateOutId).then(res => {
        let item = res.data.data.result;
        this.inApplicant = item.inApplicant;
        this.inProjectName = item.inProjectName;
        this.totalPrice = item.totalPrice;
        this.productList = item.productList;
        this.outKeeper = item.outKeeper;
        this.outTime = item.outTime;
        this.remark = item.remark;
      });
    }
  }
};